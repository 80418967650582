import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField'; // Import TextField from Material-UI
import './Login.css';
import image from './eShop-LOGO-BlackE.png';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { FacebookLogin } from '@greatsumini/react-facebook-login';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { createButton } from "react-social-login-buttons";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import Button from '@mui/material/Button';
import { height, width } from '@mui/system';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import NoLogo from './No_logo.png';

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const endpoint = '/login';
  const loginUrl = `${apiUrl}${endpoint}`;
  const companyID = localStorage.getItem('companyID');
  const companyName = localStorage.getItem('companyName');
  const { companyDetails } = useCompanyContext();
  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const dynamicURL = companyID ? `/home/${companyID}` : '/';
  const [formData, setFormData] = useState({
    useremail: '',
    password: '',
  });
  const [newAddress, setNewAddress] = useState({
    email: '',
    new_password: '',
    confirm_new_password: ''

  });
  const [emailExists, setEmailExists] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [otpVerified, setOtpVeified] = useState(false);
  const [otpFromServer, setOtpFromServer] = useState('');
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload()
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleReChange = (e) => {
    setNewAddress({
      ...newAddress,
      [e.target.name]: e.target.value
    });
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(apiUrl + '/forgot_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newAddress.email })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("data ===================== ", data);
      if (data.result.success === 'NO') {
        alert(data.result.message, 'data of the forgot password')
        setEmailExists(false);
        setOpen(true);
        // Handle if email already exist
      }
      if (data.result.success === 'YES') {
        alert(data.result.message)
        setEmailExists(true); // Email exists, set to true
        setEmailVerified(true);
        setOtpFromServer(data.result.emailOtp);
      }
    } catch (error) {
      console.error("Failed to add address:", error);
    }
  };

  const validateOtp = () => {
    if (newAddress.passcode === otpFromServer) {
      setOtpVeified(true);
    } else {
      alert("invalid OTP !")
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    if (newAddress.new_password !== newAddress.confirm_new_password) {
      alert("Passwords do not match");
    }
    const response = await fetch(apiUrl + '/update_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: newAddress.email,
        password: newAddress.new_password,
        confirmPassword: newAddress.confirm_new_password,
      }),
    });

    const responseData = await response.json();
    console.log(responseData);
  };

  formData.companyName = companyName;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const resultData = JSON.parse(responseData.result);
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
          // Redirect to home page after successful login
          if (location.state && location.state.fromCheckout) {
            navigate('/cart');
          } else {
            // Redirect to home page after successful login
            window.location.href = '/';
          }
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          // Handle failed login
          Swal.fire({
            icon: 'info',
            title: 'Error',
            text: resultData.message,
          });
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
        localStorage.removeItem('user');
        // Handle server error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Internal server error while submit Form.',
        });
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Internal Sever Error.',
      });
    }
  };

  const responseMessage = async (response) => {
    console.log(response, ' google response----------->')
    const decoded = jwtDecode(response.credential)
    const user_email = decoded.email
    const user_name = decoded.name
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login_googole';
    const registerUrlGoogle = `${apiUrl}${endpoint}`;
    console.log("google ===================== ", registerUrlGoogle)
    const formData = {
      username: user_name,
      useremail: user_email,
    };
    console.log(formData)
    try {
      const responseValue = await fetch(registerUrlGoogle, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(responseValue, 'response')
      if (responseValue.ok) {
        const responseData = await responseValue.json();
        console.log(responseData, 'responseData')
        const resultData = JSON.parse(responseData.result);
        console.log(resultData, 'resultdata')
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
          console.log(localStorage, 'localstorage............')

          // Redirect to home page after successful login
          window.location.href = '/';
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          localStorage.clear();
          // Handle failed login
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
    }
  };


  const responseMessageFacebook = async (response) => {
    const profile = response.data
    console.log(profile.email)
    console.log(response, 'response----------->')

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login_googole';
    const registerUrlGoogle = `${apiUrl}${endpoint}`;

    const formData = {
      username: profile.name,
      useremail: profile.email,
    };
    console.log(formData)
    try {
      const responseValue = await fetch(registerUrlGoogle, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(responseValue, 'response')
      if (responseValue.ok) {
        const responseData = await responseValue.json();
        console.log(responseData, 'responseData')
        const resultData = JSON.parse(responseData.result);
        console.log(resultData, 'resultdata')
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
          console.log(localStorage, 'localstorage............')

          // Redirect to home page after successful login
          window.location.href = '/';
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          localStorage.clear();
          // Handle failed login
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
    }

  }

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
      <div className="page-container">
        <div className="e_login">
          <div className="login">
            <div className="e_login_content">
              <div className="">
                <Link to={dynamicURL}>
                  {logoSrc ? <img src={logoSrc} style={{ width: '80px', height: '80px', borderRadius: '50%', border: '1px solid gray' }} alt="Company Logo" /> : <img src={NoLogo} style={{ width: '80px', height: '80px', borderRadius: '50%', border: '1px solid gray' }} alt="No logo" />}
                </Link>
              </div>
              <br />
              <h1 className="e_head">Sign In</h1>
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="useremail"
                  label="Email address"
                  type="email"
                  size="small"
                  // placeholder="Enter Email"
                  name="useremail"
                  value={formData.useremail}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  fullWidth
                  margin="normal"
                // Decrease the margin-bottom
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  // placeholder="Enter Password"
                  size="small"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ mb: 0 }} // Decrease the margin-bottom
                />

                {/* <input type="email" placeholder="email" />
              <input type="password" placeholder="password" /> */}
                {/* <span className="remember">I accept term</span> */}
                <div class="forget"><Link to='/forgot_password'>Forgot Password?</Link></div>
                <span className="clearfix" />
                <Button variant='contained' type="submit" sx={{ width: '100%' }} style={{ backgroundColor: '#375E97' }}>Sign In</Button>
              </Form>
              {/* <span className="loginwith" style={{ fontWeight: '300' }}>Or Connect with</span>
              <br />
              <div className="social-login">
                <GoogleLogin className="social-btn google-btn" onSuccess={responseMessage}
                  onError={errorMessage}><FcGoogle /> Continue with Google</GoogleLogin>
                <LoginSocialFacebook appId="1078994883190453" className="social-btn facebook-btn" onResolve={responseMessageFacebook}
                  onReject={(error) => {
                    console.log(error);
                  }}><FaFacebook /> Continue with Facebook</LoginSocialFacebook>
              </div> */}
              {/* <div className="login-container">
                <GoogleLogin
                  className="custom-google-login"
                  title="Login"
                  onSuccess={responseMessage}
                  onError={errorMessage}
                />
                <LoginSocialFacebook
                  appId="1078994883190453"
                  onResolve={responseMessageFacebook}
                  onReject={(error) => {
                    console.log(error);
                  }}
                >
                  <FacebookLoginButton style={{ width: '210px', height: '39px' }}>Facebook</FacebookLoginButton>
                </LoginSocialFacebook>
              </div> */}
              {/* <div className="login-container">
                <GoogleLogin
                  className="custom-google-login"
                  title="Login"
                  onSuccess={responseMessage}
                  onError={errorMessage}
                  style={{
                    width: '100%',
                    height: '39px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    backgroundColor: '#4285F4',
                    color: '#fff',
                    borderRadius: '5px',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                />
                <LoginSocialFacebook
                  appId="1078994883190453"
                  onResolve={responseMessageFacebook}
                  onReject={(error) => {
                    console.log(error);
                  }}
                >
                  <FacebookLoginButton
                    style={{
                      width: '100%',
                      height: '39px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      backgroundColor: '#3b5998',
                      color: '#fff',
                      borderRadius: '5px',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Facebook
                  </FacebookLoginButton>
                </LoginSocialFacebook>
              </div> */}

              <br />
              <div className="e_register">
                <p>
                  New Customer ?{" "}
                  <span>
                    <Link to='/register' state={{ fromCart: location.state }}>Sign Up</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;