import React from 'react';
import { useLocation } from 'react-router-dom';
import './StepBar.css'; // Import the CSS file

const StepBar = () => {
  const location = useLocation();
  const steps = [
    { path: '/cart', label: 'Cart' },
    { path: '/checkout', label: 'Add Address' },
    { path: '/payment', label: 'Payment' },
  ];

  const currentStepIndex = steps.findIndex(step => location.pathname.includes(step.path));

  // Calculate the width percentage of the blue line based on the current step
  const progressWidth = (currentStepIndex / (steps.length - 1)) * 100;

  return (
    <div className="step-bar">
      <div className="step-connector-background"></div> {/* Gray background line */}
      <div
        className="step-connector-progress"
        style={{ width: `${progressWidth}%` }}
      ></div> {/* Blue progress line */}
      {steps.map((step, index) => (
        <div key={index} className={`step ${index <= currentStepIndex ? 'active' : ''}`}>
          <div className="step-number">{index + 1}</div>
          <div className="step-label">{step.label}</div>
        </div>
      ))}
    </div>
  );
};

export default StepBar;
