// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useProductContext } from '../../Contexts/ProductContext';
// import './CategoryCarouselMobile.css';

// const CategoryCarouselMobile = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   // Filter out categories where the first product has on_website: false
//   const categorizedProducts = Object.entries(products.reduce((acc, product) => {
//     if (!acc[product.parent_category]) {
//       acc[product.parent_category] = [];
//     }
//     acc[product.parent_category].push(product);
//     return acc;
//   }, {})).reduce((acc, [category, products]) => {
//     if (products[0] && products[0].on_website) {
//       acc[category] = products;
//     }
//     return acc;
//   }, {});

//   return (
//     <div className="category-carousel-mobile-container">
//       {/* <h2 className="categories-heading">Categories</h2> */}
//       <div className="categories-slider">
//         {Object.entries(categorizedProducts).map(([category, products]) => (
//           <Link
//             key={category}
//             to={`/categorycards/${encodeURIComponent(category)}`}
//             className="category-link"
//           >
//             <div className="category">
//               <img
//                 src={decodeBase64Image(products[0].parent_category_image)}
//                 alt={category}
//                 title={category}
//                 loading="lazy"
//                 className="category-image"
//               />
//               <div className="category-name">{category}</div>
//             </div>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CategoryCarouselMobile;



import React from 'react';
import { Link } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import './CategoryCarouselMobile.css';

const CategoryCarouselMobile = ({ searchTerm }) => {
  const { products } = useProductContext();

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  // Filter out categories where the first product has on_website: false
  const categorizedProducts = Object.entries(products.reduce((acc, product) => {
    if (!acc[product.parent_category]) {
      acc[product.parent_category] = [];
    }
    acc[product.parent_category].push(product);
    return acc;
  }, {})).reduce((acc, [category, products]) => {
    if (products[0] && products[0].on_website) {
      acc[category] = products;
    }
    return acc;
  }, {});

  const safeSearchTerm = (searchTerm || '');

  // Filter categories based on the search term (case insensitive)
  const filteredCategories = Object.entries(categorizedProducts).filter(([category]) =>
    category.toLowerCase().includes(safeSearchTerm.toLowerCase())
  );

  if (filteredCategories.length <= 1) {
    return null; 
  }

  return (
    <div className="category-carousel-mobile-container">
      {/* <h2 className="categories-heading">Categories</h2> */}
      <div className="categories-slider">
        {filteredCategories.map(([category, products]) => (
          <Link
            key={category}
            to={`/categorycards/${encodeURIComponent(category)}`}
            className="category-link"
          >
            <div className="category">
              <img
                src={decodeBase64Image(products[0].parent_category_image)}
                alt={category}
                title={category}
                loading="lazy"
                className="category-image"
              />
              <div className="category-name">{category}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryCarouselMobile;
