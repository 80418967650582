import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const PaymentCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the checkout page immediately
    navigate('/checkout');
  }, [navigate]); // dependency array includes navigate to ensure stability

  return (
    <div></div>
  );
};