import React, { useState, useEffect, useContext } from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import CategoryCarousel from '../CategoryCarousel/CategoryCarousel';
import TrendingItemsCarousel from '../Trending/Trending';
import { Carousel2 } from '../Carousel2/Carousel2';
import { Banner } from '../Banner/Banner';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CategoryCarouselMobile from '../CategoryCarouselMobile/CategoryCarouselMobile';
import HomePageProductSlider from '../Carousel/Carousel';
import { HomePageExtend } from '../../Apps/HomePageExtend';
import './Homepage.css';
import MobileFooter from '../MobileFooter/MobileFooter';
import { Helmet } from 'react-helmet';
import NoProduct from './noproduct.png';

const Homepage = ({ searchTerm }) => {
  const { products, loading } = useProductContext();
  const { company } = useCompanyContext();
  // const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const placeholderArray = ['Search "sugar"', 'Search "flour"', 'Search "bread"', 'Search "milk"'];
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholderArray[0]);
  const companyName = localStorage.getItem('companyName');
  const companyImage = localStorage.getItem('companyImage');

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true'); // Set flag in sessionStorage
      window.location.reload(); // Trigger hard reload
    }
    console.log("company in home page =============", company);
    console.log("company image in home page =============", companyImage);

  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 553);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("products============", products);

    if (!loading && products.length > 0) {
      setDataLoaded(true);
    }
  }, [loading, products]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex(prevIndex => (prevIndex + 1) % placeholderArray.length);
    }, 1500); // 2000ms = 2 seconds

    setCurrentPlaceholder(placeholderArray[placeholderIndex]);

    return () => clearInterval(intervalId);
  }, [placeholderIndex, placeholderArray]);


  var abc;
  if (products && products.length > 0) {
    const bannerObjValDot = products[0];
    abc = bannerObjValDot['bannerObjVal'];
  }

  return (
    <div className="homepage-container" style={{ minHeight: '900px', position: 'relative', background: '#e7f2f7' }}>
      {!dataLoaded && (
        <div className="overlay">
          {/* <div className='loader' style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}> */}

          <Box sx={{ display: 'flex' }}>
            <CircularProgress size={60} />
          </Box>
          {/* </div> */}
        </div>
      )
      }
      {/* <br /> */}
      {dataLoaded && (
        //   {products.length === 0 ? (
        //     <div className="no-products">
        //       <img src={NoProduct} alt='No Product' />
        //     </div>
        <>
          {isMobileView ? (
            <CategoryCarouselMobile searchTerm={searchTerm} />
          ) : (
            <CategoryCarousel searchTerm={searchTerm} />
          )}
          <br />
          <br />
          <Carousel2 searchTerm={searchTerm} />
          <TrendingItemsCarousel />
          {/* <Benefits/> */}
          {/* <HomePageExtend customProp="kawal Value"/> */}
        </>
      )
      }
      {
        isMobileView && (
          <MobileFooter />
        )
      }

    </div >
  );
}

export default Homepage;
