import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './Search.css';
import { useProductContext } from '../../Contexts/ProductContext';
import { Link } from 'react-router-dom';

export const Search = () => {
    const { products } = useProductContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('query') || '';

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-');
        }
        return words.slice(0, maxLength).join('-');
    };

    const filteredProducts = useMemo(() => {
        return products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [products, searchTerm]);

    return (
        <div className="search-fluid" style={{ background: '#e7f2f7', height: '100vh' }}>
            <div className="product-grids">
                {filteredProducts.map(product => (
                    <div className="grid-item" key={product.id}>
                        <div className="product-card">
                            <Link
                                // to={`/${(product.categoryHierarchy?.join('/') || '')}/?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                                to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                            >
                                <img
                                    src={`data:image/jpeg;base64,${atob(product.image)}`}
                                    alt={product.name}
                                    className="product-image"
                                />
                                <div className="products-info">
                                    <div className="product-name">{product.name}</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Search;



// import React, { useMemo } from 'react';
// import './Search.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import SearchIllus from './search_illus.jpg';
// import NoProd from './noprod.avif';

// export const Search = ({ searchTerm }) => {
//     const { products } = useProductContext();

//     const truncateNameForURL = (name, maxLength) => {
//         const words = name.split(' ');
//         if (words.length <= maxLength) {
//             return words.join('-');
//         }
//         return words.slice(0, maxLength).join('-');
//     };

//     const filteredProducts = useMemo(() => {
//         if (!searchTerm.trim()) {
//             return [];
//         }
//         return products.filter(product =>
//             product.name.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//     }, [products, searchTerm]);

//     return (
//         <div className="search-fluid">
//             {searchTerm.trim() === '' ? (
//                 <div className="no-products-message">
//                     <img
//                         src={SearchIllus}
//                     />
//                     <h2>Search for products</h2>
//                 </div>
//             ) : filteredProducts.length === 0 ? (
//                 <div className="no-products-message">
//                     <img
//                         src={NoProd}
//                     />
//                     <h2>No products found</h2>
//                 </div>
//             ) : (
//                 <div className="product-grids">
//                     {filteredProducts.map(product => (
//                         <div className="grid-item" key={product.id}>
//                             <div className="product-card">
//                                 <Link
//                                     to={`/${(product.categoryHierarchy?.join('/') || '')}/?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
//                                 >
//                                     <img
//                                         src={`data:image/jpeg;base64,${atob(product.image)}`}
//                                         alt={product.name}
//                                         className="product-image"
//                                     />
//                                     <div className="products-info">
//                                         <div className="product-namee">{product.name}</div>
//                                     </div>
//                                 </Link>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Search;
