// import React from 'react';
// import './Carousel2.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';

// export const Carousel2 = () => {
//   const { products } = useProductContext();

//   // Group products by category
//   const groupedProducts = {};
//   products.forEach(product => {
//     if (product.carousel_2) {
//       if (!groupedProducts[product.category]) {
//         groupedProducts[product.category] = [];
//       }
//       // Add product to the category, but limit to 4 products
//       if (groupedProducts[product.category].length < 4) {
//         groupedProducts[product.category].push(product);
//       }
//     }
//   });

//   console.log("grouped *************************** ", groupedProducts)

//   // Function to truncate the product name
//   const truncateName = (name, maxLength) => {
//     if (name.length <= maxLength) return name;
//     return name.split(' ').slice(0, maxLength).join(' ') + '...';
//   };

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
//     }
//     return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
//   };

//   return (
//     <div className="containerr-fluid">
//       <div className="row gx-0">
//         {Object.keys(groupedProducts).map(category => (
//           <div className="col-xl-3 col-lg-12 col-md-12" key={category}>
//             {/* <h2 className='grid-heading' style={{ textAlign: 'center' }}>
//               {category}
//             </h2> */}
//             <div className="css-175oi2r gdd-458345" style={{ borderRadius: "8px", marginBottom: '35px' }}>
//               {groupedProducts[category].map(product => (
//                 <div className="grid-formation grid-column-2" key={product.id}>
//                   <div className="css-175oi2r css_mmrfs" style={{ marginRight: 4, marginLeft: 4, marginBottom: 8 }}>
//                     <a className="_3n8fnaug" href={product.link}>
//                       <div className='_3eeeee'>
//                         <div>

//                           <Link
//                             to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
//                           >
//                             <img
//                               src={`data:image/jpeg;base64,${atob(product.image)}`}
//                               alt={product.title}
//                               style={{
//                                 width: "100%",
//                                 margin: "auto",
//                                 display: "block",
//                                 objectFit: "initial",
//                                 opacity: 1,
//                                 aspectRatio: "1 / 1"
//                               }}
//                             />
//                             <div className="">
//                               <div className="titlell product-name">{product.name}</div>
//                             </div>
//                           </Link>
//                           {/* {truncateName(product.name, 3)} */}
//                         </div>

//                       </div>

//                     </a>
//                   </div>
//                 </div>
//               ))}
//               <div className="am-cm-cardui-footer">
//                 {/* <Link to={`/${groupedProducts[category][0].category_hierarchy.join('/')}`}
//                   state={{ category: category }}
//                   className="image-link see-more">See more</Link> */}
//                 {/* <Link to={`/${encodeURIComponent(groupedProducts[category][0].parent_category)}/${encodeURIComponent(category)}`}
//                 state = {{category: category}}
//                 className="image-link see-more">See more</Link> "See more" button */}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;


// import React from 'react';
// import './Carousel2.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';

// export const Carousel2 = () => {
//   const { products } = useProductContext();

//   // Group products by category
//   const groupedProducts = {};
//   products.forEach(product => {
//     if (product.carousel_2) {
//       if (!groupedProducts[product.category]) {
//         groupedProducts[product.category] = [];
//       }
//       // Add product to the category, but limit to 6 products
//       if (groupedProducts[product.category].length < 6) {
//         groupedProducts[product.category].push(product);
//       }
//     }
//   });

//   // Function to truncate the product name
//   const truncateName = (name, maxLength) => {
//     if (name.length <= maxLength) return name;
//     return name.split(' ').slice(0, maxLength).join(' ') + '...';
//   };

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-');
//     }
//     return words.slice(0, maxLength).join('-');
//   };

//   return (
//     <div className="containers-fluid">
//       <div className="row gx-0">
//         {Object.keys(groupedProducts).map(category => (
//           <div className="col-xl-12 col-lg-12 col-md-12" key={category}>
//             <h2 className='grid-heading' style={{ textAlign: 'left' }}>
//               {category}
//               <Link to={`/${groupedProducts[category][0].category_hierarchy.join('/')}`} className="see-all">
//                 see all
//               </Link>
//             </h2>

//             <Swiper
//               spaceBetween={20}
//               slidesPerView={4}
//               navigation
//               loop
//               breakpoints={{
//                 430: { slidesPerView: 3 },
//                 640: { slidesPerView: 4 },
//                 768: { slidesPerView: 5 },
//                 1024: { slidesPerView: 7 },
//               }}
//             >
//               {groupedProducts[category].map(product => (
//                 <SwiperSlide key={product.id}>
//                   <div className="product-card">
//                     <Link to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}>
//                       <img
//                         src={`data:image/jpeg;base64,${atob(product.image)}`}
//                         alt={product.name}
//                         className="product-image"
//                       />
//                       <div className="product-info">
//                         <div className="product-name">{product.name}</div>
//                         <div className="product-price">₹{product.price}</div>
//                       </div>
//                     </Link>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             </Swiper>
//             <br />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;



// import React, { useMemo } from 'react';
// import './Carousel2.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';

// // Utility function to shuffle an array
// const shuffleArray = (array) => {
//   return array.sort(() => Math.random() - 0.5);
// };

// export const Carousel2 = () => {
//   const { products } = useProductContext();

//   // Shuffle products on every render
//   const shuffledProducts = useMemo(() => shuffleArray([...products.filter(product => product.carousel_2)]), [products]);

//   // Function to truncate the product name
//   const truncateName = (name, maxLength) => {
//     if (name.length <= maxLength) return name;
//     return name.split(' ').slice(0, maxLength).join(' ') + '...';
//   };

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
//     }
//     return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
//   };

//   return (
//     <div className="containerr-fluid">
//       <div className='carousel2-heading'>
//         <h2>
//           Shop By Categories
//         </h2>
//       </div>
//       <div className="product-grids">
//         {shuffledProducts.map(product => (
//           <div className="grid-item" key={product.id}>
//             <div className="product-card">
//               <Link
//                 to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
//               >
//                 <img
//                   src={`data:image/jpeg;base64,${atob(product.image)}`}
//                   alt={product.title}
//                   className="product-image"
//                 />
//                 <div className="products-info">
//                   <div className="product-name">{product.name}</div>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;








// import React, { useMemo } from 'react';
// import './Carousel2.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';

// // Utility function to shuffle an array
// const shuffleArray = (array) => {
//   return array.sort(() => Math.random() - 0.5);
// };

// export const Carousel2 = () => {
//   const { products } = useProductContext();

//   const subcategories = useMemo(() => {
//     const categories = products
//       .filter(product => product.carousel_2 && product.category_hierarchy.length > 1)
//       .map(product => ({
//         id: product.id,
//         subcategory: product.category_hierarchy[1],
//         image: product.image,
//         parentCategory: product.category_hierarchy[0]
//       }));
//     return shuffleArray(categories);
//   }, [products]);

//   return (
//     <div className="containerr-fluid">
//       <div className='carousel2-heading'>
//         <h2>
//           Shop By Subcategories
//         </h2>
//       </div>
//       <div className="product-grids">
//         {subcategories.map(subcategory => (
//           <div className="grid-item" key={subcategory.id}>
//             <div className="product-card">
//               {/* <Link to={`/${subcategory.parentCategory}/${subcategory.subcategory}`}> */}
//               <Link
//                 to={{
//                   pathname: `/${subcategory.parentCategory}/${subcategory.subcategory}`,
//                   state: { selectedCategory: subcategory.subcategory }
//                 }}
//               >
//                 <img
//                   src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
//                   alt={subcategory.subcategory}
//                   className="product-image"
//                 />
//                 <div className="products-info">
//                   <div className="product-name">{subcategory.subcategory}</div>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;




// import React, { useMemo } from 'react';
// import './Carousel2.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';

// // Utility function to shuffle an array
// const shuffleArray = (array) => {
//   return array.sort(() => Math.random() - 0.5);
// };

// export const Carousel2 = ({ searchTerm }) => {
//   const { products } = useProductContext();

//   const subcategories = useMemo(() => {
//     // Create a Set to track unique subcategories
//     const uniqueSubcategoriesSet = new Set();

//     // Extract categories and filter out duplicates
//     const categories = products
//       .filter(product => product.carousel_2 && product.category_hierarchy.length > 1)
//       .map(product => ({
//         id: product.id,
//         subcategory: product.category_hierarchy[1],
//         image: product.image,
//         parentCategory: product.category_hierarchy[0]
//       }))
//       .filter(({ subcategory }) => {
//         if (uniqueSubcategoriesSet.has(subcategory)) {
//           return false; // Skip if subcategory is already in the set
//         }
//         uniqueSubcategoriesSet.add(subcategory);
//         return true; // Include if subcategory is unique
//       });

//     // return shuffleArray(categories);
//     return categories;
//   }, [products]);

//   return (
//     <div className="containerr-fluid">
//       <div className='carousel2-heading'>
//         <h2>
//           Shop By Categories
//         </h2>
//       </div>
//       <div className="product-grids">
//         {subcategories.map(subcategory => (
//           <div className="grid-item" key={subcategory.id}>
//             <div className="product-card">
//               <Link to={`/${encodeURIComponent(subcategory.parentCategory)}`}
//                 state={{ selectedCategory: subcategory.subcategory }}>
//                 {/* <Link
//                 to={{
//                   // pathname: `/${subcategory.parentCategory}/${subcategory.subcategory}`,
//                   pathname: `/${subcategory.parentCategory}`,
//                   state: { selectedCategory: subcategory.subcategory }
//                 }}
//               > */}
//                 <img
//                   src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
//                   alt={subcategory.subcategory}
//                   className="product-image"
//                 />
//                 <div className="products-info">
//                   <div className="product-name">{subcategory.subcategory}</div>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;


import React, { useMemo } from 'react';
import './Carousel2.css';
import { useProductContext } from '../../Contexts/ProductContext';
import { Link } from 'react-router-dom';

// Utility function to shuffle an array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export const Carousel2 = ({ searchTerm }) => {
  const { products } = useProductContext();

  const subcategories = useMemo(() => {
    // Create a Set to track unique subcategories
    const uniqueSubcategoriesSet = new Set();

    // Extract categories, filter out duplicates and apply searchTerm filter
    const categories = products
      .filter(product => product.carousel_2 && product.category_hierarchy.length > 1)
      .map(product => ({
        id: product.id,
        subcategory: product.category_hierarchy[1],
        image: product.image,
        parentCategory: product.category_hierarchy[0]
      }))
      .filter(({ subcategory }) => {
        if (uniqueSubcategoriesSet.has(subcategory)) {
          return false; // Skip if subcategory is already in the set
        }
        uniqueSubcategoriesSet.add(subcategory);
        return true; // Include if subcategory is unique
      })
      .filter(({ subcategory }) => {
        return subcategory.toLowerCase().includes(searchTerm.toLowerCase()); // Filter by search term
      });

    return categories; // return shuffleArray(categories);
  }, [products, searchTerm]); // Include searchTerm in dependencies

  return (
    <div className="containerr-fluid">
      <div className='carousel2-heading'>
        <h2>
          Shop By Categories
        </h2>
      </div>
      <div className="product-grids">
        {subcategories.map(subcategory => (
          <div className="grid-item" key={subcategory.id}>
            <div className="product-card">
              <Link to={`/${encodeURIComponent(subcategory.parentCategory)}`}
                state={{ selectedCategory: subcategory.subcategory }}>
                <img
                  src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
                  alt={subcategory.subcategory}
                  className="product-image"
                />
                <div className="products-info">
                  <div className="product-name">{subcategory.subcategory}</div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel2;

