// // TrendingItemsCarousel.js
// import React from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './Trending.css';

// const TrendingItemsCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   const trendingProducts = products.filter((product) => product.trending);


//   const carouselOptions = {
//     items: 4,
//     margin: 20,
//     // nav: true,
//     dots: false,
//     responsive: {
//       0: {
//         items: 2 // Show only one item in mobile view
//       },
//       479: {
//         items: 2 // Show two items in tablet view
//       },
//       700: {
//         items: 3
//       },
//       800: {
//         items: 4 // Show four items in desktop view
//       },
//       1093: {
//         items: 5
//       }
//     }
//   };

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
//     }
//     return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
//   };

//   return (
//     <div className="carousel-box pt-3 pb-5">
//       <div className="container-fluid">
//         <h2 className="text-start mb-5">Shop Our Trending Items</h2>
//         <OwlCarousel className="owl-carousel3 owl-theme" {...carouselOptions}>
//           {trendingProducts.map((item, index) => (
//             <div key={index} className="item">
//               <div className="shop1">
//                 <Link
//                   to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   <img
//                     src={decodeBase64Image(item.image)}
//                     alt=""
//                     title=""
//                     loading="lazy"
//                     className="img-fluid"
//                     style={{
//                       objectFit: 'contain',
//                       borderRadius: 0,
//                     }}
//                   />
//                   <div className=""></div>
//                   <div className="shop-content">
//                     <h3>{item.name}</h3>
//                     <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
//                       <span style={{
//                         fontSize: '14px',
//                         position: 'relative',
//                         top: '-7px',
//                         fontSize: '13px',
//                         paddingRight: '3px'
//                       }}>$</span>{item.price}
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>
//     </div>
//   );
// };

// export default TrendingItemsCarousel;




// import React from 'react';
// import Slider from 'react-slick';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './Trending.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";


// // Custom arrows
// const CustomPrevArrow = (props) => {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
//       <FaChevronCircleLeft style={{ color: '#4f73f7', fontSize: '28px' }} />
//     </div>
//   );
// };

// const CustomNextArrow = (props) => {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
//       <FaChevronCircleRight style={{ color: '#4f73f7', fontSize: '28px' }} />
//     </div>
//   );
// };

// const TrendingItemsCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   const trendingProducts = products.filter((product) => product.trending);

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-');
//     }
//     return words.slice(0, maxLength).join('-');
//   };

//   const sliderSettings = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     arrows: true,
//     prevArrow: <CustomPrevArrow />,
//     nextArrow: <CustomNextArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="trending-carousel-box pt-3 pb-5">
//       <div className="container-fluid">
//         <h2 className="text-start mb-5">Shop Our Trending Items</h2>
//         <Slider {...sliderSettings}>
//           {trendingProducts.map((item, index) => (
//             <div key={index} className="item">
//               <div className="shop1">
//                 <Link
//                   to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   <img
//                     src={decodeBase64Image(item.image)}
//                     alt={item.name}
//                     loading="lazy"
//                     className="img-fluid"
//                     style={{
//                       objectFit: 'contain',
//                       borderRadius: 0,
//                       height: '150px',
//                       width: '100%',
//                     }}
//                   />
//                   <div className="shop-content">
//                     <h3>{item.name}</h3>
//                     <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
//                       <span style={{
//                         fontSize: '14px',
//                         position: 'relative',
//                         top: '-7px',
//                         paddingRight: '3px'
//                       }}>$</span>{item.price}
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default TrendingItemsCarousel;






import React from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import { Link } from 'react-router-dom';
import './Trending.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

// Custom arrows
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const TrendingItemsCarousel = () => {
  const { products } = useProductContext();

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  const trendingProducts = products.filter((product) => product.trending);

  if (trendingProducts.length === 0) {
    return null;
  }

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-');
    }
    return words.slice(0, maxLength).join('-');
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Function to group products into chunks of 4
  const groupProductsForMobile = (products) => {
    const groupedProducts = [];
    for (let i = 0; i < products.length; i += 4) {
      groupedProducts.push(products.slice(i, i + 4));
    }
    return groupedProducts;
  };

  const mobileGroupedProducts = groupProductsForMobile(trendingProducts);

  return (
    <div className="trending-carousel-box pt-3 pb-5">
      {trendingProducts !== 0 && <div className="container-fluid" style={{ padding: 0 }}>
        <h2 className="text-start mb-5">Shop Our Trending Items</h2>

        {/* Desktop and Tablet View */}
        <div className="desktop-carousel">
          <Slider {...sliderSettings}>
            {trendingProducts.map((item, index) => (
              <div key={index} className="item">
                <div className="shop1">
                  <Link
                    to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img
                      src={decodeBase64Image(item.image)}
                      alt={item.name}
                      loading="lazy"
                      className="img-fluid"
                      style={{
                        objectFit: 'contain',
                        borderRadius: 0,
                        height: '150px',
                        width: '100%',
                      }}
                    />
                    <div className="shop-content">
                      <h3>{item.name}</h3>
                      <span className="old-price" style={{ fontSize: '20px', color: 'black' }}>
                        <span style={{
                          fontSize: '14px',
                          position: 'relative',
                          top: '-7px',
                          paddingRight: '3px'
                        }}>₹</span>{item.price}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Mobile View Display 4 Products in One Div */}
        <div className="mobile-carousel">
          <Slider {...sliderSettings}>
            {mobileGroupedProducts.map((group, index) => (
              <div key={index} className="mobile-slide">
                <div className="mobile-grid">
                  {group.map((item, idx) => (
                    <div key={idx} className="mobile-item">
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img
                          src={decodeBase64Image(item.image)}
                          alt={item.name}
                          loading="lazy"
                          className="img-fluid"
                          style={{
                            objectFit: 'contain',
                            height: '120px',
                            width: '100%',
                          }}
                        />
                        <div className="mobile-shop-content">
                          <h3>{item.name}</h3>
                          <h3>₹ {item.price}</h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>}
    </div>
  );
};

export default TrendingItemsCarousel;
