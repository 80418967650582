// import React from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './CategoryCarousel.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';

// const CategoryCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   // Filter out products with on_website: false for each category
//   const categorizedProducts = Object.entries(products.reduce((acc, product) => {
//     if (!acc[product.parent_category]) {
//       acc[product.parent_category] = [];
//     }
//     acc[product.parent_category].push(product);
//     return acc;
//   }, {})).reduce((acc, [category, products]) => {
//     if (products[0] && products[0].on_website) {
//       acc[category] = products;
//     }
//     return acc;
//   }, {});

//   const carouselOptions = {
//     items: 4,
//     loop: false,
//     margin: 20,
//     dots: false,
//     nav: true,
//     responsive: {
//       0: {
//         items: 2 // Show only one item in mobile view
//       },
//       600: {
//         items: 3 // Show two items in tablet view
//       },
//       992: {
//         items: 4 // Show four items in desktop view
//       },
//       1208: {
//         items: 5
//       }
//     }
//   };



//   console.log("categorizedProducts", categorizedProducts);

//   return (
//     <div className="carousel-box pt-3">
//       <div className="container-fluid">
//         <OwlCarousel className="owl-carousel3 owl-theme" {...carouselOptions}>
//           {Object.entries(categorizedProducts).map(([category, products]) => (
//             <div key={category} className="item">
//               <div className="special-overlay-inner">
//                 <div className="special-overlay-item">
//                   <div className="special-content">
//                     <h3>{category}</h3>
//                     {/* <p>{products.length} PRODUCTS</p> */}
//                   </div>
//                   <a href="#" className="special-img">
//                     <Link to={`/${encodeURIComponent(category)}`}
//                       state={{ category: category }}
//                       className="image-link">
//                       <img
//                         src={decodeBase64Image(products[0].image)}
//                         alt={products[0].name}
//                         title={products[0].name}
//                         loading="lazy"
//                         className="img-fluid"
//                         style={{ borderRadius: 0, marginTop: '50px', objectFit: 'contain' }}
//                       />
//                       <div className="special-overlay"></div>
//                     </Link>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>
//     </div>
//   );
// };

// export default CategoryCarousel;


// import React from 'react';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './CategoryCarousel.css';

// const CategoryCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   // Filter out products with on_website: false for each category
//   const categorizedProducts = Object.entries(products.reduce((acc, product) => {
//     if (!acc[product.parent_category]) {
//       acc[product.parent_category] = [];
//     }
//     acc[product.parent_category].push(product);
//     return acc;
//   }, {})).reduce((acc, [category, products]) => {
//     if (products[0] && products[0].on_website) {
//       acc[category] = products;
//     }
//     return acc;
//   }, {});

//   return (
//     <div className="static-grid-container pt-3">
//       <div className="container-fluid">
//         <div className="static-grid">
//           {Object.entries(categorizedProducts).map(([category, products]) => (
//             <div key={category} className="grid-item">
//               <div className="special-overlay-inner">
//                 <div className="special-overlay-item">
//                   <div className="special-content">
//                     <h3>{category}</h3>
//                   </div>
//                   <a href="#" className="special-img">
//                     <Link to={`/${encodeURIComponent(category)}`}
//                       state={{ category: category }}
//                       className="image-link">
//                       <img
//                         src={decodeBase64Image(products[0].image)}
//                         alt={products[0].name}
//                         title={products[0].name}
//                         loading="lazy"
//                         className="img-fluid"
//                         style={{ borderRadius: 0, marginTop: '50px', objectFit: 'contain' }}
//                       />
//                       <div className="special-overlay"></div>
//                     </Link>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CategoryCarousel;



// import React from 'react';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './CategoryCarousel.css';

// const CategoryCarousel = ({ searchTerm }) => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   // Filter out products with on_website: false for each category
//   const categorizedProducts = Object.entries(products.reduce((acc, product) => {
//     if (!acc[product.parent_category]) {
//       acc[product.parent_category] = [];
//     }
//     acc[product.parent_category].push(product);
//     return acc;
//   }, {})).reduce((acc, [category, products]) => {
//     if (products[0] && products[0].on_website) {
//       acc[category] = products;
//     }
//     return acc;
//   }, {});

//   if (Object.keys(categorizedProducts).length <= 1) {
//     return null; // render the carousel for more than 1 category only
//   }

//   return (
//     <div className="static-grid-container pt-3">
//       <div className="container-fluid">
//         <div className="static-grid">
//           {Object.entries(categorizedProducts).map(([category, products]) => (
//             <div key={category} className="grid-item">
//               <Link to={`/${encodeURIComponent(category)}`}
//                 state={{ category: category }}
//                 className="image-link">
//                 <div className="special-overlay-inner">
//                   <img
//                     src={decodeBase64Image(products[0].parent_category_image)}
//                     alt={category}
//                     title={category}
//                     loading="lazy"
//                     className="img-fluid circular-img"
//                   />
//                 </div>
//                 <div className="special-content">
//                   <h3>{category}</h3>
//                 </div>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CategoryCarousel;


import React from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import { Link } from 'react-router-dom';
import './CategoryCarousel.css';

const CategoryCarousel = ({ searchTerm }) => {
  const { products } = useProductContext();


  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  // Filter out products with on_website: false for each category
  const categorizedProducts = Object.entries(products.reduce((acc, product) => {
    if (!acc[product.parent_category]) {
      acc[product.parent_category] = [];
    }
    acc[product.parent_category].push(product);
    return acc;
  }, {})).reduce((acc, [category, products]) => {
    if (products[0] && products[0].on_website) {
      acc[category] = products;
    }
    return acc;
  }, {});


  const safeSearchTerm = (searchTerm || '');

  // Filter categories based on the search term (case insensitive)
  const filteredCategories = Object.entries(categorizedProducts).filter(([category]) =>
    category.toLowerCase().includes(safeSearchTerm.toLowerCase())
  );

  if (filteredCategories.length <= 1) {
    return null; // render the carousel for more than 1 category only
  }

  return (
    <div className="static-grid-container pt-3">
      <div className="container-fluid">
        <div className="static-grid">
          {filteredCategories.map(([category, products]) => (
            <div key={category} className="grid-item">
              <Link to={`/${encodeURIComponent(category)}`}
                state={{ category: category }}
                className="image-link">
                <div className="special-overlay-inner">
                  <img
                    src={decodeBase64Image(products[0].parent_category_image)}
                    alt={category}
                    title={category}
                    loading="lazy"
                    className="img-fluid circular-img"
                  />
                </div>
                <div className="special-content">
                  <h3>{category}</h3>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryCarousel;
