import React, { useEffect, useState } from 'react';

const SitemapComponent = () => {
    const [sitemapContent, setSitemapContent] = useState(null);

    useEffect(() => {
        const fetchSitemap = async () => {
            try {
                const response = await fetch('/sitemap.xml');
                const text = await response.text();
                setSitemapContent(text);
            } catch (error) {
                console.error('Error fetching sitemap:', error);
            }
        };

        fetchSitemap();
    }, []);

    return (
        <div>
            <pre>{sitemapContent}</pre>
        </div>
    );
};

export default SitemapComponent;