// // ProductContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';

// const ProductContext = createContext();

// export const useProductContext = () => useContext(ProductContext);

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_BASE_URL;
//   // const companyName = localStorage.getItem('companyName');
//   const companyName = "general-stores";
//   const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';
//   const getProductUrl = `${apiUrl}${dynamicURL}`;

//   useEffect(() => {
//     const getProducts = async () => {
//       try {
//         const response = await fetch(getProductUrl, {
//           method: 'GET',
//         });
//         const data = await response.json();
//         if (data && data.length > 0) {
//           setProducts(data);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     getProducts();
//   }, [getProductUrl]);

//   return (
//     <ProductContext.Provider value={{ products }}>
//       {children}
//     </ProductContext.Provider>
//   );
// };



// ProductContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const companyName = localStorage.getItem('companyName');
  const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';

  const getProductUrl = `${apiUrl}${dynamicURL}`;

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetch(getProductUrl, {
          method: 'GET',
        });
        const data = await response.json();
        if (data && data.length > 0) {
          setProducts(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getProducts();
  }, [getProductUrl]);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};
