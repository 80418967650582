import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './Address.css';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert, Grid } from '@mui/material';
import MobileFooter from '../MobileFooter/MobileFooter';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useProductContext } from '../../Contexts/ProductContext';

// Custom arrows
const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
            <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
            <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
        </div>
    );
};

const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
};

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const getRateEndpoint = '/get_rate';
const createCheckoutEndpoint = '/create-checkout-session';
const url = `${apiUrl}${getRateEndpoint}`;
const checkouturl = `${apiUrl}${createCheckoutEndpoint}`;


export const Address = () => {
    const { products } = useProductContext();
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState({
        street: '',
        city: '',
        country: '',
        state: '',
        zip: ''
    });
    const [open, setOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({
        street: '',
        city: '',
        pincode: '',
        country: '',
        state: ''
    });
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const { state } = useLocation();
    const { cartItems = [], totalAmount = 0, additionalData = {} } = state || {};
    const [rate, setRate] = useState(null);
    const isLoggedIn = localStorage.getItem('user');

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateFields = () => {
        let tempErrors = {};
        if (!newAddress.street) tempErrors.street = "Street is required";
        if (!newAddress.city) tempErrors.city = "City is required";
        if (!newAddress.pincode) tempErrors.pincode = "Pincode is required";
        if (!newAddress.country) tempErrors.country = "Country is required";
        if (!newAddress.state) tempErrors.state = "State is required";
        return tempErrors;
    };

    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const deleteAddress = async (addressId) => {
        try {
            const response = await fetch(`${apiUrl}/delete_address`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: addressId, user: isLoggedIn })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            window.location.reload();
        } catch (error) {
            console.error("Failed to delete address:", error);
        }
    }

    useEffect(() => {
        const listAddresses = async () => {
            const listAddressesurl = apiUrl + '/list_addresses';
            try {
                const response = await fetch(listAddressesurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user: isLoggedIn })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.status);
                }

                const addresses = await response.json();
                setAddresses(addresses.result.addresses);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        if (isLoggedIn) {
            listAddresses();
        }
    }, [isLoggedIn, apiUrl]);

    const submitNewAddress = async () => {
        if (addresses.length >= 5) {
            handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
            return;
        }

        const tempErrors = validateFields();
        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            return;
        }

        try {
            const response = await fetch(apiUrl + '/add_address', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newAddress: newAddress, user: isLoggedIn })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            handleClose();
            window.location.reload();
        } catch (error) {
            console.error("Failed to add address:", error);
        }
    };

    const handleOpen = (event) => {
        event.preventDefault();
        if (addresses.length >= 5) {
            handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
            return;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrors({});
    };

    const handleChange = (e) => {
        setNewAddress({
            ...newAddress,
            [e.target.name]: e.target.value
        });
        if (errors[e.target.name]) {
            setErrors({
                ...errors,
                [e.target.name]: null
            });
        }
    };

    const handleSelectAddress = (selectedAddress) => {
        setAddress(selectedAddress);
    };

    const handlePincodeChange = (event) => {
        const { value, name } = event.target;
        const cleanedValue = value.replace(/[^0-9-\s]+/g, '');
        setNewAddress(prev => ({
            ...prev,
            [name]: cleanedValue
        }));

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: null
            });
        }
    };

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    // Function to group products into chunks of 4
    const groupProductsForMobile = (products) => {
        const groupedProducts = [];
        for (let i = 0; i < products.length; i += 4) {
            groupedProducts.push(products.slice(i, i + 4));
        }
        return groupedProducts;
    };

    const mobileGroupedProducts = groupProductsForMobile(products);

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
        }
        return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
    };


    return (
        <main style={{ padding: isMobileView ? '0px 20px' : '0px 80px', background: '#e7f2f7' }}>
            <div className="dlvr_adds" style={{ 'paddingTop': '30px' }}>
                <div className="container-fluid">
                    <div className="dlvr_contt">
                        <h2>Manage Address</h2>
                        <form className="shipp_address">
                            {addresses.map((address, index) => (
                                <div className="form-group" key={index}>
                                    <div>
                                        <input
                                            type="radio"
                                            name="accent-group"
                                            className="accent-black black accent-color"
                                            id={`accent-black${index}`}
                                            onChange={() => handleSelectAddress(address)}
                                            style={{ display: 'inline' }}
                                        />
                                        <label htmlFor={`accent-black${index}`} style={{ display: 'inline' }}>
                                            {address.street}, {address.city}, {address.zip}, {address.country}
                                        </label>
                                    </div>
                                    <div>
                                        <Button variant='outlined' color='error' size='small'
                                            onClick={() => deleteAddress(address.id)}
                                            sx={{ marginTop: '5px', height: '24px' }}>Delete</Button>
                                    </div>
                                </div>
                            ))}
                            <div className="form__confirmation">
                                <Button style={{ backgroundColor: '#375E97' }} variant='contained' size='small' onClick={handleOpen}>+ Add New Address</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="street"
                        name="street"
                        label="Street"
                        type="text"
                        fullWidth
                        required
                        value={newAddress.street}
                        onChange={handleChange}
                        error={!!errors.street}
                        helperText={errors.street}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="city"
                                name="city"
                                label="City"
                                type="text"
                                fullWidth
                                required
                                value={newAddress.city}
                                onChange={handleChange}
                                error={!!errors.city}
                                helperText={errors.city}
                                sx={{ marginBottom: '15px' }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="pincode"
                                name="pincode"
                                label="Zipcode"
                                type="text"
                                fullWidth
                                required
                                value={newAddress.pincode}
                                onChange={handlePincodeChange}
                                error={!!errors.pincode}
                                helperText={errors.pincode}
                                sx={{ marginBottom: '15px' }}
                            />
                        </Grid>
                    </Grid>
                    <RegionDropdown
                        country={newAddress.country}
                        value={newAddress.state}
                        onChange={(val) => setNewAddress({ ...newAddress, state: val })}
                        classes="form-control"
                        blankOptionLabel="Select State"
                        style={{
                            borderColor: errors.state ? 'red' : undefined, height: '56px',
                            padding: '10px 14px', borderRadius: '4px'
                        }}
                    />
                    {errors.state && <p style={{
                        color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
                        marginTop: '3px'
                    }}>{errors.state}</p>}
                    <CountryDropdown
                        value={newAddress.country}
                        onChange={(val) => setNewAddress({ ...newAddress, country: val })}
                        classes="form-control"
                        style={{
                            borderColor: errors.country ? 'red' : undefined, height: '56px',
                            padding: '10px 14px', borderRadius: '4px', marginTop: '15px'
                        }}
                    />
                    {errors.country && <p style={{
                        color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
                        marginTop: '3px'
                    }}>{errors.country}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={submitNewAddress} color="primary">Add Address</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div>
                <h3 style={{ textAlign: 'center' }}>Our products</h3>
                <br />
                <div className="desktop-carousel">
                    <Slider {...sliderSettings}>
                        {products.map((item, index) => (
                            <div key={index} className="item">
                                <div className="shop1">
                                    <Link
                                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <img
                                            src={decodeBase64Image(item.image)}
                                            alt={item.name}
                                            loading="lazy"
                                            className="img-fluid"
                                            style={{
                                                objectFit: 'contain',
                                                borderRadius: 0,
                                                height: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <div className="shop-content">
                                            <h3>{item.name}</h3>
                                            <span className="old-price" style={{ fontSize: '20px', color: 'black' }}>
                                                <span style={{
                                                    fontSize: '14px',
                                                    position: 'relative',
                                                    top: '-7px',
                                                    paddingRight: '3px'
                                                }}>₹</span>{item.price}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                {/* Mobile View Display 4 Products in One Div */}
                <div className="mobile-carousel">
                    <Slider {...sliderSettings}>
                        {mobileGroupedProducts.map((group, index) => (
                            <div key={index} className="mobile-slide">
                                <div className="mobile-grid">
                                    {group.map((item, idx) => (
                                        <div key={idx} className="mobile-item">
                                            <Link
                                                to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                                <img
                                                    src={decodeBase64Image(item.image)}
                                                    alt={item.name}
                                                    loading="lazy"
                                                    className="img-fluid"
                                                    style={{
                                                        objectFit: 'contain',
                                                        height: '120px',
                                                        width: '100%',
                                                    }}
                                                />
                                                <div className="mobile-shop-content">
                                                    <h3>{item.name}</h3>
                                                    <h3>₹ {item.price}</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {isMobileView && (
                <MobileFooter />
            )}
        </main>
    );
};

export default Address;
