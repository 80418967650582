// PaymentSuccess.js

import React, { useEffect} from 'react';
import { useCartContext } from '../../Contexts/CartContext';
import { Link } from "react-router-dom";
import './PaymentSuccess.css';
import Button from '@mui/material/Button';

export const PaymentSuccess = () => {
  const { cartItems, clearCart } = useCartContext();

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');
  const rate = urlParams.get('rate');

  useEffect(() => {
    if (orderId) {
      triggerApi(orderId);
    }
    
  }, [orderId]);

  const clearCartApi = async () => {
    try {
      clearCart();
      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user_id) {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const endpoint = '/clearcart';
        const url = `${apiUrl}${endpoint}`;

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id }),
        });

        if (!response.ok) {
          throw new Error('Failed to clear cart on the server');
        }

        console.log('Cart cleared on the server successfully.');
      } else {
        console.error('User ID not found in currentUser data');
      }
    } catch (error) {
      console.error('Error clearing cart on the server:', error);
    }
  };

  const triggerApi = async (orderId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const endpoint = '/convert-to-sales-order';
      const url = `${apiUrl}${endpoint}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ order_id: orderId, rate: Number(rate) })
      });
      const data = await response.json();
      console.log(data);

      clearCartApi();
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="payment-success-container"> {/* Apply the container class */}
      <div>
        <h1 className="payment-success-heading">Your Payment is Successful.</h1> {/* Apply the heading class */}
        <p>You can track your orders <Link to="/myorders" className="blue-link">here</Link>.</p>
        <Link to={'/'}>
          <Button variant='contained' color='success'>
            Continue Shopping
          </Button>
        </Link>
      </div>
    </div>
  );
};
