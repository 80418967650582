import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // For extracting URL params
import './MyOrdersForm.css';
import axios from 'axios';

const MyOrdersForm = () => {
    const { orderid } = useParams(); // Extract the order_id from the URL
    const [orderDetails, setOrderDetails] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    console.log("orderid =================== ", orderid);

    useEffect(() => {
        if (orderid) {
            axios.post(apiUrl + '/myorder/form/details', { order_id: orderid })
                .then((response) => {
                    console.log("response ========== ", response);
                    if (response.data.result.success) {
                        setOrderDetails(response.data.result.order_details);
                        setLoading(false);
                    } else {
                        console.log("error fetching data")
                    }
                })
                .catch((error) => {
                    setError('Failed to fetch order details');
                    setLoading(false);
                });
        }
    }, []);

    console.log("order details =================== ", orderDetails);

    return (
        <div className="myorder-form">
            <div className="container">
                <div className="myorder-fcont">
                    <div className="myorder_con myorder_p">
                        <h2>Order summary</h2>
                        <div className="myorder_arrive">
                            {orderDetails?.order_name || 'Loading...'}
                        </div>
                    </div>

                    <div className="myorder_items myorder_borderb">
                        <h3>{orderDetails?.order_lines?.length || 0} items in this order</h3>
                        {orderDetails?.order_lines?.map((item, index) => (
                            <div key={index} className="myorder-box myorder-flex">
                                <div className="myorder-flex" style={{ gap: 10 }}>
                                    <div className="myorder_primg">
                                        <img
                                            src={`data:image/jpeg;base64,${item.product_image}`}
                                            alt={item?.name}
                                            className="dk-pro"
                                        />
                                    </div>
                                    <div>
                                        <div>{item.product_name}</div>
                                        <div style={{ marginTop: 14 }}>
                                            <span>{item.quantity} x ₹{item.price_unit}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: 44, fontWeight: 700 }}>
                                    ₹{item.subtotal}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="myorder_items myorder_borderb">
                        <div className="myorder_bill">
                            <h3>Bill Details</h3>
                            <div className="myorder_sl myorder_sp">
                                <div>Total Amount</div>
                                <div>₹{orderDetails?.total_amount || 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default MyOrdersForm