import React from 'react';
import Benefits from '../Benefits/Benefits';

const AboutUs = () => {
  return (
    <>
    <br/>
    <br/>
    <Benefits/>
    </>
  );
};

export default AboutUs;